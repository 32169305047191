//@ts-nocheck
import React, { memo, useEffect, useState } from "react";
import { useMutation } from "react-query";
import Image from "next/image";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ModalForm, CustomInput, PhoneConfirmation } from "components";
import { isEmailValid, isNameValid, isPhoneValid } from "src/utils/validation";
import calculateFinalPrice from "src/utils/calculateFinalPrice";

import stylesModule from "./BookingModal.module.scss";

import { basePostRequest } from "utils/api/api";
import {
  questBookingUrl,
  saveClientUrl,
  sendCodeUrl,
  checkCodeUrl,
} from "utils/api/requestUrls";
import { BOOKING_STATUS, PHONE_STATUS, payment } from "utils/constants";
import { styles } from "./styles";
import classNames from "classnames";

interface BookingModalProps {
  questId: string;
  isOpen: boolean;
  setOpen: any;
  title: string;
  address: string;
  date: string;
  time: string;
  extraPrice: string;
  startDateTime?: string;
  prices: any;
  bookingId?: string;
  changeBookingState: any;
  pageView: any;
  questFirstData: any;
  updateData?: any;
}

export default memo(function BookingModal(props: BookingModalProps) {
  const classes = styles();
  const invalidVerificationCode = (text: string) =>
    toast.error(text, {
      position: toast.POSITION.TOP_CENTER,
    });

  const {
    isOpen,
    setOpen,
    title,
    address,
    time,
    date,
    extraPrice,
    bookingId,
    prices,
    changeBookingState,
    questFirstData,
  } = props;

  const [formData, setFormData] = useState<any>({
    clientId: null,
    questEventId: Number(bookingId),
    orderStatus: BOOKING_STATUS.WAIT_FOR_APPROVE,
    paymentType: "Cash",
    finalPrice: 0,
    countOfPlayers: 2,
    paidMoney: 0,
    comment: "",
  });

  const [ageCheckbox, setAgeCheckbox] = useState<boolean>(false);

  const finilPrice = calculateFinalPrice(
    extraPrice,
    prices.find(
      (item) => item.countOfPlayers === Number(formData.countOfPlayers)
    ).price
  );

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      finalPrice: Number(finilPrice),
    }));
  }, [finilPrice])

  const [clientInfo, setClientInfo] = useState<any>({
    name: "",
    email: "",
    phoneNumber: "+375",
  });

  const clientFieldsModel = [
    {
      type: "text",
      name: "name",
      label: "Имя",
      value: clientInfo.name,
      isValid: isNameValid,
      required: true,
    },
    {
      type: "text",
      name: "phoneNumber",
      label: "Телефон",
      value: clientInfo.phoneNumber,
      isValid: isPhoneValid,
      required: true,
    },
  ];

  const bookingFieldsModel = [
    {
      type: "select",
      name: "countOfPlayers",
      label: "Количество участников",
      value: formData.countOfPlayers,
      options: prices,
      required: true,
    },
    {
      type: "select",
      name: "paymentType",
      label: "Тип оплаты",
      value: formData.paymentType,
      options: payment,
      required: true,
    },
    {
      type: "text",
      name: "promo",
      label: "Сертификат",
      value: formData.promo,
      required: false,
    },
    {
      type: "text",
      name: "comment",
      label: "Комментарий",
      value: formData.comment,
      required: false,
    },
  ];

  const handleBookingFieldsChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClientFieldsChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      setClientInfo((prevState) => ({
        ...prevState,
        [name]: value.replaceAll(' ', ''),
      }));
    } else {
      setClientInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const isFormValid =
    isNameValid(formData.name) &&
    isPhoneValid(formData.phone) &&
    (!!formData.email ? isEmailValid(formData.email) : true);

  const { mutate: handleBookingSubmit, isLoading: isBookingSubmitLoading } =
    useMutation(() => basePostRequest(questBookingUrl(bookingId), formData), {
      onSuccess: () => {
        setOpen(false);
        changeBookingState(true);
      },
      onError: (err) => {
        console.warn("error", err);
      },
    });

  const { mutate: saveClientSubmit, isLoading: saveClientLoading } =
    useMutation(() => basePostRequest(saveClientUrl, clientInfo), {
      onSuccess: (res) => {
        setFormData((prevState) => ({
          ...prevState,
          clientId: res.id,
        }));
        handleBookingSubmit();
      },
      onError: (err) => {
        console.warn("error", err);
      },
    });

  const handleClientConfirmation = () => {
    if (isPhoneValid(clientInfo.phoneNumber) && isNameValid(clientInfo.name)) {
      if (!!clientInfo.email) {
        if (isEmailValid(clientInfo.email)) {
          saveClientSubmit();
        } else {
          invalidVerificationCode('Неверный формат почты!');
        };
      } else {
        saveClientSubmit();
      };
    } else {
      invalidVerificationCode(!isPhoneValid(clientInfo.phoneNumber) ? "Неверный формат номера телефона!" : 'Некорректные данные!');
    }
  };

  const isFieldVisible = (field) => {
    if (field.name === "promo") {
      if (formData.paymentType === "Certificate") {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <ModalForm
      {...{
        modalTitle: title,
        isOpen,
        setOpen,
        onSubmit: handleClientConfirmation,
        submitButtonLabel: "Забронировать",
        modalWidth: "md",
        isShowPrivacyPolice: true,
      }}
    >
      <ToastContainer />

      <div
        className={
          "flex flex-shrink-0 flex-col items-start justify-start text-lg mb-4 w-full"
        }
      >
        <div className={stylesModule.Title}>Бронирование квеста</div>
      </div>
      <div className={stylesModule.QuestCard}>
        <div className={stylesModule.Image}>
          <Image
            className={stylesModule.MainQuestImage}
            src={questFirstData?.imageFilePath}
            loader={() => questFirstData?.imageFilePath}
            alt={questFirstData?.imageFilePath}
            width={140}
            height={140}
          />
        </div>
        <div className={stylesModule.Block2}>
          <div className={stylesModule.QuesInfoWrapper}>
            <div className={stylesModule.QuestName}>
              {questFirstData?.name}
            </div>
            <div className={stylesModule.QuestAddress}>
              <Image
                src="/icons/address.svg"
                alt="address icon"
                width={"20"}
                height={"20"}
              />
              <span>{questFirstData?.address}</span>
            </div>

            <div className={stylesModule.CardQuestInfo}>
              <div className={stylesModule.CardQuestInfoBlock}>
                <div className={stylesModule.CardQuestInfoBlockItem}>
                  <Image
                    src="/icons/clock.svg"
                    alt="address icon"
                    width={"20"}
                    height={"20"}
                  />
                  <span> {questFirstData?.duration}</span>
                </div>
                <div className={stylesModule.CardQuestInfoBlockItem}>
                  <Image
                    src="/icons/people.svg"
                    alt="address icon"
                    width={"20"}
                    height={"20"}
                  />
                  <span>{questFirstData?.countOfPlayersInfo} человек</span>
                </div>
              </div>

              <div className={stylesModule.CardQuestInfoBlock}>
                <div className={stylesModule.CardQuestInfoBlockItem}>
                  <Image
                    src="/icons/age.svg"
                    alt="address icon"
                    width={"20"}
                    height={"20"}
                  />
                  <span>
                    от {questFirstData?.ageInfo.replace("+", "")} лет
                  </span>
                </div>
                <div className={stylesModule.CardQuestInfoBlockItem}>
                  <Image
                    src="/icons/horror.svg"
                    alt="address icon"
                    width={20}
                    height={20}
                  />
                  <span>
                    {questFirstData?.withActors
                      ? "С актером"
                      : "Без актера"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={"flex font-normal w-full mt-4"}>
            <span className={stylesModule.Price}>
              Стоимость:{" "}
              <span className={stylesModule.PricePrice}>
                {finilPrice} BYN
              </span>
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap flex-row">
        {clientFieldsModel.map((item, i) =>
          i === 0 ? (
            <div key={item.name} className={"w-full sm:w-full mb-2"}>
              <CustomInput
                {...item}
                helperText="Поле обязательное!"
                onChange={(e) => handleClientFieldsChange(e)}
                isIframe={props?.isIframe}
              />
            </div>
          ) : (
            <div key={item.name} className={"w-1/2 sm:w-full mb-2"}>
              <CustomInput
                {...item}
                helperText="Поле обязательное!"
                onChange={(e) => handleClientFieldsChange(e)}
                isIframe={props?.isIframe}
              />
            </div>
          )
        )}

        {bookingFieldsModel.map(
          (item, i) =>
            isFieldVisible(item) && (
              <div
                key={item.name}
                className={classNames({
                  "sm:w-full": true,
                  "w-1/2": i === 0 ,
                  "w-full": i === 2 || i === 3 || i === 1,
                })}
              >
                <CustomInput
                  {...item}
                  helperText="Поле обязательное!"
                  onChange={(e) => handleBookingFieldsChange(e)}
                  isIframe={props?.isIframe}
                />
              </div>
            )
        )}

        {/* {Number(questFirstData?.ageInfo?.replace("+", "")) >= 14 && (
          <div className="flex flex-row  sm:justify-center mt-2">
            <div className="w-4 h-4">
              <input
                className="w-4 h-4 bg-amber-500 hover:bg-amber-700 checked:bg-rose-500 cursor-pointer"
                type="checkbox"
                id="age_checkbox"
                checked={ageCheckbox}
                onChange={(e) => setAgeCheckbox(e.target.checked)}
              />
            </div>
            <label
              for="age_checkbox"
              className="ml-3 text-sm font-bold cursor-pointer"
            >
              Все игроки команды старше 14 лет и в день игры никому из
              игроков не исполняется 14 лет
            </label>
          </div>
        )} */}
      </div>
    </ModalForm>
  );
});
