import React, { memo } from "react";
import styles from './PageWrapper.module.scss';

const PageWrapper = ({ children }) => {
  return (
    <div className={styles.Wrapper}>
      <div className="flex flex-shrink-0 flex-col w-full md:max-w-6xl lg:max-w-6xl xl:max-w-6xl mb-4">
        {children}
      </div>
    </div>
  );
};

export default memo(PageWrapper);