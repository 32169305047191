export const headerConfig = [
  {label: 'Квесты', href: '/'},
  {label: 'Рейтинг', href: '/raiting-kvestov'},
  {label: 'Акции', href: '/skidki'},
  {label: 'Отзывы', href: '/otzyvy'},
  {label: 'Новости', href: '/novosti'},
  {label: 'Вакансии', href: '/vakansii'},
  {label: 'Контакты', href: '/kontakty'},
];

export const payment = [
  {
    value: 'Cash',
    label: 'Наличные',
  },
  {
    value: 'Card',
    label: 'Карта',
  },
  {
    value: 'Certificate',
    label: 'Сертификат',
  },
];

export enum ROLE_ENUM {
  MANAGER = 'ROLE_MANAGER',
  ACTOR = 'ROLE_ACTOR',
  ADMIN = 'ROLE_ADMIN',
};

export enum BOOKING_STATUS {
  ABLE_TO_ORDER = 'ABLE_TO_ORDER',
  WAIT_FOR_APPROVE = 'WAIT_FOR_APPROVE',
  ORDERED = 'ORDERED',
  CANCELLED = 'CANCELLED',
  BLOCKED = 'BLOCKED',
};

export enum PHONE_STATUS {
  INCORRECT = 'INCORRECT',
  CONFIRMED = 'CONFIRMED',
  EXPIRED = 'EXPIRED',
};

export enum BOOKING_STATUS_ID {
  ABLE_TO_ORDER = 1,
  WAIT_FOR_APPROVE = 2,
  ORDERED = 3,
  CANCELLED = 4,
  BLOCKED = 5,
};

export enum PAYMENT_TYPE_ID {
  Cash = 1,
  Card = 2,
  Certificate = 3,
  'Cash+Certificate' = 4,
  'Card+Certificate' = 5,
};

type urlNamesType = {
  path: string;
  name: string;
};

export const urlNames: Array<urlNamesType> = [
  {
    path: '/novichkam',
    name: 'Новичкам',
  },
  {
    path: '/detskie',
    name: 'Детские',
  },
  {
    path: '/s-akterom',
    name: 'С актером',
  },
  {
    path: '/strashnye',
    name: 'Страшные',
  },
  {
    path: '/ekshn',
    name: 'Экшн',
  },
  {
    path: '/opytnym',
    name: 'Опытным',
  },
  {
    path: '/po-filmam',
    name: 'По фильмам',
  },
  {
    path: '/kontakty',
    name: 'Контакты',
  },
  {
    path: '/privacy-policy',
    name: 'Политика конфиденциальности',
  },
  {
    path: '/polzovatelskoe-soglashenie',
    name: 'Пользовательское соглашение',
  },
  {
    path: '/skidki',
    name: 'Акции',
  },
  {
    path: '/novosti',
    name: 'Новости',
  },
  // {
  //   path: '/certificates',
  //   name: 'Сертификаты',
  // },
  {
    path: '/otzyvy',
    name: 'Отзывы',
  },
  {
    path: '/raiting-kvestov',
    name: 'Рейтинг',
  },
  {
    path: '/amerikanskaya-istoriya-uzhasov-2-frik-shou',
    name: 'Американская история ужасов 2: ФРИК ШОУ',
  },
  {
    path: '/amerikanskaya-istoriya-uzhasov',
    name: 'Американская история ужасов',
  },
  {
    path: '/sinister-pohititel-detskih-dush',
    name: 'SINISTER. Похититель детских душ',
  },
  {
    path: '/zapretnaya-zona-zov-pripyati',
    name: 'Запретная зона. Зов Припяти',
  },
  {
    path: '/resident-evil-obitel-zla',
    name: 'Resident Evil (Обитель зла)',
  },
  {
    path: '/tayny-inkvizicii',
    name: 'Тайны Инквизиции',
  },
  {
    path: '/garri-potter-krestrazh',
    name: 'Гарри Поттер. Крестраж',
  },
  {
    path: '/sokrovische-drakona',
    name: 'Сокровище дракона',
  },
  {
    path: '/missiya-nevypolnima-kartel',
    name: 'Миссия невыполнима: Картель',
  },
  {
    path: '/logovo-bezumca-kids-horror-11',
    name: 'Логово безумца (Kids Horror 11+)',
  },
  {
    path: '/molchanie-yagnyat-vstrecha-s-gannibalom-lekterom',
    name: 'Молчание ягнят. Встреча с Ганнибалом Лектером',
  },
  {
    path: '/zvonok',
    name: 'Звонок',
  },
  {
    path: '/tehasskaya-reznya-benzopiloy',
    name: 'Техасская резня бензопилой',
  },
  {
    path: '/izgnanie-kids-horror-11',
    name: 'Изгнание (Kids Horror 11+)',
  },
  {
    path: '/kvest/proklyatie-horror-11',
    name: 'Проклятие (Horror 11+)',
  },
  {
    path: '/kvest/fort-boyard-kids',
    name: 'Форт Боярд Kids',
  },
  {
    path: '/kvest/igrovoe-prostranstvo-game-park',
    name: 'Игровое пространство GAME PARK',
  },
  {
    path: '/kvest/prostranstvo-dlya-prazdnika-game-zone',
    name: 'Пространство для праздника GAME ZONE',
  },
  {
    path: '/kvest/alisa-v-strane-chudes-po-sledam-belogo-krolika',
    name: 'Алиса в стране чудес. По следам белого кролика',
  },
  {
    path: '/vakansii',
    name: 'Вакансии',
  },
];

