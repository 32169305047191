import React, { memo } from "react";
import Image from "next/image";

import styles from "./TextComponent.module.scss";
import classNames from "classnames";

const TextComponent = (props: any) => {
  const { page, title, descriptionTexts, isContactsBlock } = props;

  return (
    <div
      className={classNames({
        [styles.TextComponent]: true,
        [styles.TextComponentBgMain]: page === "main-page",
        [styles.TextComponentBgRaiting]: page === "raiting",
        [styles.TextComponentBgReviews]: page === "reviews",
        [styles.TextComponentBgSkidki]: page === "skidki",
        [styles.TextComponentBgRabota]: page === "rabota",
        [styles.TextComponentBgNews]: page === "news",
        [styles.TextComponentBgContacts]: page === "contacts",
      })}
    >
      <div
        className={classNames({
          [styles.TextComponentTextWrapper]: true,
          [styles.TextComponentTextWrapperNews]: page === "news",
        })}
      >
        <h1 className={styles.TextComponentTextTitle}>{title}</h1>

        <div
          className={classNames({
            [styles.TextComponentTextDescription]: true,
            [styles.TextComponentTextDescriptionSecond]: page === "skidki",
          })}
        >
          {descriptionTexts}
        </div>

        {isContactsBlock && (
          <div className={styles.IsContactsBlock}>
            <div className={styles.IsContactsBlockItem}>
              <Image
                src={`/icons/phone.svg`}
                alt="TT logo"
                width={24}
                height={24}
              />
              <a href="tel:+375447710940">+375-44-771-09-40</a>
            </div>

            <div className={styles.IsContactsBlockItem}>
              <Image
                src={`/icons/mail.svg`}
                alt="TT logo"
                width={24}
                height={24}
              />
              <a href="mailto:oldmouseminsk@gmail.com">
                oldmouseminsk@gmail.com
              </a>
            </div>

            <div className={styles.SocialIconsWrapper}>
              <a target="_blank" href="https://www.tiktok.com/@questminsk">
                <Image
                  className={styles.SocialIcon}
                  src={`/contactsIcons/tiktok.svg`}
                  alt="TT logo"
                  width={42}
                  height={42}
                />
              </a>

              <a target="_blank" href="https://vk.com/oldmouseminsk">
                <Image
                  className={styles.SocialIcon}
                  src={`/contactsIcons/vk.svg`}
                  alt="TT logo"
                  width={42}
                  height={42}
                />
              </a>

              <a target="_blank" href="https://www.instagram.com/oldmouse.by">
                <Image
                  className={styles.SocialIcon}
                  src={`/contactsIcons/instagram.svg`}
                  alt="TT logo"
                  width={42}
                  height={42}
                />
              </a>
            </div>
          </div>
        )}
        {/* 
        {page === "reviews" && (
          <div className={styles.Reviews}>Оставте отзыв на странице квеста</div>
        )} */}

        {page === "skidki" && (
          <>
            <div className={styles.TextComponentTextDescriptionSecond}>
              На одну игру распространяется одна акция.
            </div>
            <div className={styles.TextComponentTextDescriptionSecond}>
              Вы можете выбрать одну из предлагаемых нами скидок или акций на
              свое усмотрение.
            </div>
          </>
        )}
        {page === "rabota" && (
          <>
            <div className={styles.TextComponentTextDescriptionSecondSmall}>
              Работу в крупной компании – лидере рынка индустрии квестов.
            </div>
            <div className={styles.TextComponentTextDescriptionSecondSmall}>
              Возможность профессионального развития и обучения.
            </div>
            <div className={styles.TextComponentTextDescriptionSecondSmall}>
              Отличные условия для роста и самореализации.
            </div>
            <div className={styles.TextComponentTextDescriptionSecondSmall}>
              Сплоченную команду профессионалов и единомышленников.
            </div>
            <div className={styles.TextComponentTextDescriptionSecondSmall}>
              Конкурентоспособную и достойную заработную плату.
            </div>
            <div className={styles.TextComponentTextDescriptionSecondSmall}>
              Прозрачную систему вознаграждений.
            </div>
            <div className={styles.TextComponentTextDescriptionSecondSmall}>
              Гибкий подход к графику работы.
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(TextComponent);
