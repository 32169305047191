import React, { memo } from 'react';
import { useRouter } from 'next/router';
import { PageWrapper, HeaderWeb, HeaderMobile } from 'components';

const Header = (props: any) => {
  const router = useRouter()

  const isTabActive = (item: any) => {
    if (item.href === '/otzyvy') {
      return router.pathname === item.href
    };
    
    if (item.href === "/") {
      return router.pathname === item.href
    } else {
      return router.pathname.includes(item.href)
    };
  };

  return (
    <PageWrapper>
      <div className='block sm:hidden rg:hidden'>
        <HeaderWeb
          isTabActive={isTabActive}
        />
      </div>

      <div className='hidden sm:block rg:block'>
        <HeaderMobile
          isTabActive={isTabActive}
        />
      </div>
    </PageWrapper>
  );
};

export default memo(Header);