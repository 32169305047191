//@ts-nocheck
import { memo, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";

import styles from "./QuizCard.module.scss";

import { translit, useWindowSize } from "utils";
import classNames from "classnames";

const QuizCard = (props: any) => {
  const { quizListData, dataRaiting, onGiveClick } = props;
  const [questCounts, setQuestCounts] = useState(6);

  const router = useRouter();

  const size = useWindowSize();

  return (
    <>
      <div
        className={classNames({
          [styles.QuizCardsWrapper]: true,
          [styles.QuizCardsWrapperIsButtonHidden]: props?.isButtonHidden,
        })}
      >
        {quizListData?.slice(0, questCounts)?.map((quiz) => {
          const {
            id,
            name,
            ageInfo,
            countOfPlayersInfo,
            difficulty,
            address,
            imageFilePath,
            progressStatus,
            horror,
            duration,
            withActors,
          } = quiz;

          const handleOpenClick = (e) => {
            if (!progressStatus) {
              e.preventDefault();
              router.push({
                pathname: `/kvest/${translit(name)}`,
              });
            }
          };

          const handleBookingClick = (e) => {
            e.stopPropagation();
            if (!progressStatus) {
              e.preventDefault();
              router.push(`/kvest/${translit(name)}?block=booking`);
            }
          };

          return (
            <div
              key={id + name}
              className={classNames({
                [styles.Card]: true,
                [styles.CardProgress]: progressStatus,
              })}
              onClick={(e) => handleOpenClick(e)}
            >
              <div className={styles.ImageMain}>
                <div className={styles.Image}>
                  <Image
                    className={classNames({
                      [styles.MainQuestImage]: true,
                      [styles.ImageInProgress]: progressStatus,
                    })}
                    src={imageFilePath}
                    loader={() => imageFilePath}
                    alt={imageFilePath}
                    layout="fill"
                    objectFit="contain"
                    // width={size?.width > 600 ? 355 : 355}
                    // height={size?.width > 600 ? 220 : 200}
                  />
                  {progressStatus && (
                    <div className={styles.InProgressWrapper}>В разработке</div>
                  )}
                  <div className={styles.RaitingWrapper}>
                    <Image
                      src="/icons/star.svg"
                      alt="address icon"
                      width={20}
                      height={20}
                    />
                    {!dataRaiting?.filter((item) => item.questId === id)
                      .length && "10"}
                    {dataRaiting?.map(
                      (raiting) =>
                        raiting?.questId === id && (
                          <div
                            key={raiting.questId + raiting.averageOverallRating}
                          >
                            {raiting.averageOverallRating}
                          </div>
                        )
                    )}
                  </div>
                  <div className={styles.DificultiWrapper}>
                    {difficulty >= 1 ? (
                      <Image
                        src={!horror ? "/icons/lock.svg" : "/icons/horror.svg"}
                        alt="address icon"
                        width={20}
                        height={20}
                      />
                    ) : (
                      <Image
                        src={
                          !horror
                            ? "/icons/grayLock.svg"
                            : "/icons/grayHorror.svg"
                        }
                        alt="address icon"
                        width={20}
                        height={20}
                      />
                    )}
                    {difficulty >= 2 ? (
                      <Image
                        src={!horror ? "/icons/lock.svg" : "/icons/horror.svg"}
                        alt="address icon"
                        width={20}
                        height={20}
                      />
                    ) : (
                      <Image
                        src={
                          !horror
                            ? "/icons/grayLock.svg"
                            : "/icons/grayHorror.svg"
                        }
                        alt="address icon"
                        width={20}
                        height={20}
                      />
                    )}
                    {difficulty >= 3 ? (
                      <Image
                        src={!horror ? "/icons/lock.svg" : "/icons/horror.svg"}
                        alt="address icon"
                        width={20}
                        height={20}
                      />
                    ) : (
                      <Image
                        src={
                          !horror
                            ? "/icons/grayLock.svg"
                            : "/icons/grayHorror.svg"
                        }
                        alt="address icon"
                        width={20}
                        height={20}
                      />
                    )}
                    {difficulty >= 4 ? (
                      <Image
                        src={!horror ? "/icons/lock.svg" : "/icons/horror.svg"}
                        alt="address icon"
                        width={20}
                        height={20}
                      />
                    ) : (
                      <Image
                        src={
                          !horror
                            ? "/icons/grayLock.svg"
                            : "/icons/grayHorror.svg"
                        }
                        alt="address icon"
                        width={20}
                        height={20}
                      />
                    )}
                    {difficulty >= 5 ? (
                      <Image
                        src={!horror ? "/icons/lock.svg" : "/icons/horror.svg"}
                        alt="address icon"
                        width={20}
                        height={20}
                      />
                    ) : (
                      <Image
                        src={
                          !horror
                            ? "/icons/grayLock.svg"
                            : "/icons/grayHorror.svg"
                        }
                        alt="address icon"
                        width={20}
                        height={20}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.QuesInfoContainer}>
                <div className={styles.QuesInfoWrapper}>
                  <div className={styles.QuestName}>{name}</div>
                  <div className={styles.QuestAddress}>
                    <Image
                      src="/icons/address.svg"
                      alt="address icon"
                      width={"20"}
                      height={"20"}
                    />
                    <span>{address}</span>
                  </div>

                  <div
                    className={classNames({
                      [styles.CardQuestInfo]: true,
                      [styles.CardQuestInfoHidden]: progressStatus,
                    })}
                  >
                    <div className={styles.CardQuestInfoBlock}>
                      <div className={styles.CardQuestInfoBlockItem}>
                        <Image
                          src="/icons/clock.svg"
                          alt="address icon"
                          width={"20"}
                          height={"20"}
                        />
                        <span> {duration}</span>
                      </div>
                      <div className={styles.CardQuestInfoBlockItem}>
                        <Image
                          src="/icons/people.svg"
                          alt="address icon"
                          width={"20"}
                          height={"20"}
                        />
                        <span>{countOfPlayersInfo} человек</span>
                      </div>
                    </div>

                    <div className={styles.CardQuestInfoBlock}>
                      <div className={styles.CardQuestInfoBlockItem}>
                        <Image
                          src="/icons/age.svg"
                          alt="address icon"
                          width={"20"}
                          height={"20"}
                        />
                        <span>от {ageInfo?.replace("+", "")} лет</span>
                      </div>
                      <div className={styles.CardQuestInfoBlockItem}>
                        <Image
                          src="/icons/horror.svg"
                          alt="address icon"
                          width={20}
                          height={20}
                        />
                        <span>{withActors ? "С актером" : "Без актера"}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {!progressStatus && (
                  <div className={styles.ButtonWrapper}>
                    <div
                      className={styles.ButtonBooking}
                      onClick={(e) => handleBookingClick(e)}
                    >
                      Забронировать
                    </div>
                    <div
                      onClick={(e) => onGiveClick(e, id)}
                      className={styles.ButtonGift}
                    >
                      Подарить
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {questCounts < quizListData.length && !props?.isButtonHidden && (
        <div className={styles.ShowMoreWrapper}>
          <div
            className={styles.ShowMoreButton}
            onClick={() => setQuestCounts((prevState) => prevState + 6)}
          >
            <Image
              src="/icons/showMore.svg"
              alt="address icon"
              width={25}
              height={24}
            />
            <span>Показать еще</span>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(QuizCard);
