import { memo, PropsWithChildren, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import classNames from "classnames";

import styles from "./BookingSchedule.module.scss";

import BookingModal from "modals/BookingModal";
import { BOOKING_STATUS } from "utils/constants";
import capitalizeFirstLetter from "src/utils/capitalizeFirstLetter";
import _ from "lodash";

interface BookingScheduleProps extends PropsWithChildren<any> {
  schedule: any;
  prices: any;
  questName: string;
  questId: string;
  address: string;
  use: string;
  changeBookingState: any;
  pageView: any;
  questFirstData: any;
}

const BookingSchedule = (props: BookingScheduleProps) => {
  const {
    schedule,
    prices,
    questName,
    questId,
    address,
    use,
    changeBookingState,
    pageView,
    questFirstData,
    showBookingModal,
    setShowBookingModal,
  } = props;

  const [checkedDate, setCheckedDate] = useState("");
  const [checkedTime, setCheckedTime] = useState("");
  const [extraPrice, setExtraPrice] = useState("");
  const [bookingId, setBookingId] = useState("");

  const onCloseClick = (value: any) => {
    setShowBookingModal(false);
  };

  return (
    <div className={classNames({
      [styles.isIframeWrapper]: props?.isIframe
    })}>
      {schedule.map((item, i) => (
        <div
          className={styles.BookingSchedule}
          key={item.id}
        >
          <div className={styles.BookingDay}>
            <div>
              {capitalizeFirstLetter(
                dayjs(item?.[0]?.startDateTime).locale("ru").format("DD MMMM")
              )}
            </div>
            <div className={styles.BookingDayDay}>
              {capitalizeFirstLetter(
                dayjs(item?.[0]?.startDateTime).locale("ru").format("dddd")
              )}
            </div>
          </div>

          <div className={styles.BookingListWrapper}>
            {item.map((concreteTime) => {
              const { startDateTime, extraPrice, eventOrderStatus, id } =
                concreteTime;

              return (
                <div key={id} className={styles.BookingItemWrapper}>
                  <div
                    onClick={() => {
                      if (
                        eventOrderStatus.status ===
                          BOOKING_STATUS.ABLE_TO_ORDER &&
                        new Date(startDateTime) > new Date(new Date()?.getTime() + 1800000)
                      ) {
                        setShowBookingModal(!showBookingModal);
                        setCheckedDate(
                          dayjs(startDateTime).format("DD-MM-YYYY")
                        );
                        setCheckedTime(dayjs(startDateTime).format("HH:mm"));
                        setExtraPrice(extraPrice);
                        setBookingId(id);
                      }
                    }}
                  >
                    <div
                      className={classNames({
                        [styles.BookingItem]: true,
                        [styles.BookingItemDisabled]:
                          eventOrderStatus.status ===
                            BOOKING_STATUS.WAIT_FOR_APPROVE ||
                          eventOrderStatus.status === BOOKING_STATUS.ORDERED ||
                          eventOrderStatus.status === BOOKING_STATUS.BLOCKED ||
                          new Date(startDateTime) < new Date(new Date()?.getTime() + 1800000),
                      })}
                    >
                      <div
                        className={classNames({
                          [styles.BookingItemTime]: true,
                          [styles.BookingItemTimeDisabled]:
                            eventOrderStatus.status ===
                              BOOKING_STATUS.WAIT_FOR_APPROVE ||
                            eventOrderStatus.status ===
                              BOOKING_STATUS.ORDERED ||
                              eventOrderStatus.status === BOOKING_STATUS.BLOCKED ||
                            new Date(startDateTime) < new Date(new Date()?.getTime() + 1800000),
                        })}
                      >
                        {dayjs(startDateTime).format("HH:mm")}
                      </div>
                      <div
                        className={classNames({
                          [styles.BookingItemPrice]: true,
                          [styles.BookingItemPriceDisabled]:
                            eventOrderStatus.status ===
                              BOOKING_STATUS.WAIT_FOR_APPROVE ||
                            eventOrderStatus.status ===
                              BOOKING_STATUS.ORDERED ||
                              eventOrderStatus.status === BOOKING_STATUS.BLOCKED ||
                            new Date(startDateTime) < new Date(new Date()?.getTime() + 1800000),
                        })}
                      >
                        {eventOrderStatus.status ===
                          BOOKING_STATUS.WAIT_FOR_APPROVE ||
                        eventOrderStatus.status === BOOKING_STATUS.ORDERED ||
                         eventOrderStatus.status === BOOKING_STATUS.BLOCKED ||
                        new Date(startDateTime) < new Date(new Date()?.getTime() + 1800000)
                          ? "Занято"
                          : use === "quiz"
                          ? extraPrice.toFixed(0) + " BYN"
                          : concreteTime.extraPrice.toFixed(0) + " BYN"}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}

      {showBookingModal && use === "quiz" && (
        <BookingModal
          {...{
            questId,
            pageView,
            isOpen: showBookingModal,
            setOpen: onCloseClick,
            title: questName,
            address,
            date: checkedDate,
            time: checkedTime,
            extraPrice: extraPrice,
            prices,
            bookingId: bookingId,
            changeBookingState: changeBookingState,
            questFirstData,
            isIframe: props?.isIframe,
          }}
        />
      )}
    </div>
  );
};

export default memo(BookingSchedule);
