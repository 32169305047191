import { memo, useState, useEffect } from "react";
import styles from "./RecomendedQuests.module.scss";

import { QuizCard } from "components";

import { getArrayElements } from "utils";
import PageWrapper from "components/Templates/PageWrapper";

const RecomendedQuests = (props: any) => {
  const { questListFirstData } = props;

  const [recomendedQuestList, setRecomendedQuestList] = useState<any>([]);

  useEffect(() => {
    if (!!questListFirstData?.length) {
      const filteredArray = questListFirstData.filter(
        (item) => !item.progressStatus
      );
      setRecomendedQuestList(getArrayElements(filteredArray));
    }
  }, [questListFirstData]);

  return (
    <div className={styles.RecomendedWrapper}>
      <PageWrapper>
        <h1 className={styles.RecomendedBlockTitle}>
          Квесты, которые могут вам понравиться
        </h1>

        <div className={styles.RecomendedQuests}>
          <QuizCard quizListData={recomendedQuestList} isButtonHidden />
        </div>
      </PageWrapper>
    </div>
  );
};

export default memo(RecomendedQuests);
