import { useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import styles from './QuestionsAnswers.module.scss';

import QuestionsAnswersList from 'customTexts/QuestionsAnswers/questionsAnswers.json';
import { PageWrapper } from 'components';

const QuestionsAnswersItem = (item, i) => {
  const [isItemOpened, setIsItemOpened] = useState(false);
  return (
    <div
      className={classNames({
        [styles.QuestionsAnswersCard]: true,
        [styles.QuestionsAnswersCardActive]: isItemOpened
      })}
      key={i + item.title}
      onClick={() => setIsItemOpened(prevState => !prevState)}
    >
      <div className={styles.QuestionsAnswersCardTitle}>
        {item.title}

        <div className={classNames({
          [styles.QuestionsAnswersCardIcon]: true,
          [styles.QuestionsAnswersCardIconActive]: isItemOpened
        })}>
          <Image 
            src="/icons/arrow.svg" 
            alt="arrow icon" 
            width={'100%'}
            height={'100%'}
          />
        </div>
      </div>

      <div
        className={classNames({
          [styles.QuestionsAnswersCardDescription]: true,
          [styles.QuestionsAnswersCardDescriptionOpened]: isItemOpened
        })}
      >
        {item.description}
      </div>
    </div>
  )
}

const QuestionsAnswers = () => {
  return (
    <div className={styles.QuestionsAnswers}>
      <PageWrapper>
        <div className={styles.QuestionsAnswersTitle}>
          <span className='w-max border-b-2 border-red-600 py-2 leading-loose'>
            Вопросы/ответы
          </span>

        </div>

        <div className={styles.QuestionsAnswersItem}>
          {QuestionsAnswersList.map((item, i) =>
            <QuestionsAnswersItem {...item} i={i} />
          )}
        </div>
      </PageWrapper>
    </div>
  );
};

export default QuestionsAnswers;