//@ts-nocheck
import { memo, useState, useRef, useEffect } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { useQuery, useMutation } from "react-query";
import Link from "next/link";
import classNames from "classnames";
import { useOutsideClick } from "utils/index";

import { translit } from "utils";

import { baseGetRequest } from "utils/api/api";
import { quizListUrl, quizListRaitingUrl } from "utils/api/requestUrls";

import styles from "./HeaderWeb.module.scss";

import { headerConfig } from "utils/constants";

const HeaderWeb = (props: any) => {
  const { isTabActive } = props;
  const router = useRouter();

  const [isDropDownActive, setIsDropDownActive] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [search, setSearch] = useState("");

  const [questData, setQuestData] = useState([]);

  const { isLoading: isRaitingDataLoading, data: raitingData } = useQuery(
    "getRaiting",
    () => baseGetRequest(quizListRaitingUrl)
  );

  const ref = useRef();
  const refInput = useRef();

  useOutsideClick(ref, () => {
    setIsDropDownActive(false);
  });

  useOutsideClick(refInput, () => {
    if (isSearchActive) {
      setIsSearchActive(false);
    }
  });

  const onPhoneClick = () => {
    if (isDropDownActive) {
      router.push("tel:+375447710940");
    }
  };

  const goHome = () => {
    router.push("/");
  };

  const { mutate: getQuestData, isLoading: isQuestDataLoading } = useMutation(
    () => baseGetRequest(quizListUrl(null, search)),
    {
      onSuccess: (res) => {
        const shuffledArr = res?.content.sort(function () {
          return Math.random() - 0.5;
        });
        const sortedArr = shuffledArr.sort(
          (a, b) => a.progressStatus - b.progressStatus
        );

        setQuestData(sortedArr);
      },
      onError: (err) => {
        console.warn("error", err);
      },
    }
  );

  useEffect(() => {
    getQuestData();
  }, [search]);

  const handleQuestClick = (name) => {
    router.push(`/kvest/${translit(name)}`);
    setIsSearchActive(false);
    setSearch("");
  };

  return (
    <div
      className={
        "sticky flex justify-between items-center h-12 w-full my-3 px-5 z-10"
      }
    >
      <div className={"flex items-center"}>
        <Image
          onClick={goHome}
          className={"cursor-pointer"}
          src="/icons/logomouse.svg"
          alt="Oldmouse logo"
          width={128}
          height={40}
        />

        {!isSearchActive && (
          <div className={"flex justify-center items-center ml-5 sm:ml-2"}>
            {headerConfig.map((item) => (
              <Link key={item.label} href={item.href}>
                <section
                  className={classNames({
                    "sm:ml-2 ml-5 cursor-pointer": true,
                    "text-white border-b-2 border-red-600": isTabActive(item),
                    "category text-gray-400 text-opacity-60 hover:text-white":
                      !isTabActive(item),
                  })}
                >
                  {item.label}
                </section>
              </Link>
            ))}
          </div>
        )}
      </div>
      <div
        className={classNames({
          [styles.WrapperSearch]: true,
          [styles.WrapperSearchActive]: isSearchActive,
        })}
      >
        {!isSearchActive && (
          <div
            className={styles.SearchIcon}
            onClick={() => setIsSearchActive(true)}
          >
            <Image
              src={"/icons/search.svg"}
              alt="address icon"
              width={26}
              height={26}
            />
          </div>
        )}
        <a className={styles.PhoneIconMobile} href="tel:+375447710940">
          <Image
            src={"/icons/phoneHeader.svg"}
            alt="address icon"
            width={26}
            height={26}
          />
        </a>

        {isSearchActive && (
          <div ref={refInput} className={"relative w-full"}>
            <div className={"relative"}>
              <div className={styles.SearchInInput}>
                <Image
                  src={"/icons/search.svg"}
                  alt="address icon"
                  width={26}
                  height={26}
                />
              </div>
              <input
                className={styles.Input}
                value={search}
                placeholder="Введите название квеста"
                onChange={(e) => setSearch(e.target.value)}
              />
              <div
                className={styles.CrossInInput}
                onClick={() => setIsSearchActive(false)}
              >
                <Image
                  src={"/icons/cross.svg"}
                  alt="address icon"
                  width={26}
                  height={26}
                />
              </div>
            </div>
            <div className={styles.SearchDropDown}>
              <div className={styles.SearchDropDownTitle}>Квесты</div>
              {!questData?.length && (
                <div className={styles.Empty}>
                  <div>
                    <Image
                      src="/icons/empty.svg"
                      alt="address icon"
                      width={180}
                      height={180}
                    />
                  </div>
                  <div className={styles.EmptyText}>Ничего не найдено!</div>
                </div>
              )}
              <div>
                {questData?.map((item) => (
                  <div
                    className={classNames({
                      [styles.QuestItemContainer]: true,
                      [styles.QuestItemContainerInProgress]:
                        !!item.progressStatus,
                    })}
                    onClick={() => handleQuestClick(item.name)}
                  >
                    <div className={styles.QuestImageContainer}>
                      <Image
                        className={styles.MainImageQuest}
                        loader={() => item?.imageFilePath}
                        src={item?.imageFilePath}
                        height={90}
                        width={90}
                        alt={`123`}
                      />
                    </div>
                    <div className={styles.TextWrap}>
                      <div className={styles.DropName}>{item.name}</div>
                      <div className={styles.QuestWrapperInfoHeader}>
                        <div className={styles.RaitingWrapper}>
                          <Image
                            src="/icons/star.svg"
                            alt="address icon"
                            width={20}
                            height={20}
                          />
                          {raitingData?.map(
                            (raiting) =>
                              raiting?.questId === item.id && (
                                <div
                                  key={
                                    raiting.questId +
                                    raiting.averageOverallRating
                                  }
                                >
                                  {raiting.averageOverallRating}
                                </div>
                              )
                          )}
                          {!raitingData?.filter(
                            (raiting) => raiting.questId === item.id
                          ).length && "10"}
                        </div>
                        <div className={styles.Difficulty}>
                          <div className={styles.DificultiWrapper}>
                            {item.difficulty >= 1 ? (
                              <Image
                                src={
                                  !item.horror
                                    ? "/icons/lock.svg"
                                    : "/icons/horror.svg"
                                }
                                alt="address icon"
                                width={20}
                                height={20}
                              />
                            ) : (
                              <Image
                                src={
                                  !item.horror
                                    ? "/icons/grayLock.svg"
                                    : "/icons/grayHorror.svg"
                                }
                                alt="address icon"
                                width={20}
                                height={20}
                              />
                            )}
                            {item.difficulty >= 2 ? (
                              <Image
                                src={
                                  !item.horror
                                    ? "/icons/lock.svg"
                                    : "/icons/horror.svg"
                                }
                                alt="address icon"
                                width={20}
                                height={20}
                              />
                            ) : (
                              <Image
                                src={
                                  !item.horror
                                    ? "/icons/grayLock.svg"
                                    : "/icons/grayHorror.svg"
                                }
                                alt="address icon"
                                width={20}
                                height={20}
                              />
                            )}
                            {item.difficulty >= 3 ? (
                              <Image
                                src={
                                  !item.horror
                                    ? "/icons/lock.svg"
                                    : "/icons/horror.svg"
                                }
                                alt="address icon"
                                width={20}
                                height={20}
                              />
                            ) : (
                              <Image
                                src={
                                  !item.horror
                                    ? "/icons/grayLock.svg"
                                    : "/icons/grayHorror.svg"
                                }
                                alt="address icon"
                                width={20}
                                height={20}
                              />
                            )}
                            {item.difficulty >= 4 ? (
                              <Image
                                src={
                                  !item.horror
                                    ? "/icons/lock.svg"
                                    : "/icons/horror.svg"
                                }
                                alt="address icon"
                                width={20}
                                height={20}
                              />
                            ) : (
                              <Image
                                src={
                                  !item.horror
                                    ? "/icons/grayLock.svg"
                                    : "/icons/grayHorror.svg"
                                }
                                alt="address icon"
                                width={20}
                                height={20}
                              />
                            )}
                            {item.difficulty >= 5 ? (
                              <Image
                                src={
                                  !item.horror
                                    ? "/icons/lock.svg"
                                    : "/icons/horror.svg"
                                }
                                alt="address icon"
                                width={20}
                                height={20}
                              />
                            ) : (
                              <Image
                                src={
                                  !item.horror
                                    ? "/icons/grayLock.svg"
                                    : "/icons/grayHorror.svg"
                                }
                                alt="address icon"
                                width={20}
                                height={20}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {!isSearchActive && (
        <div
          onMouseEnter={() => setIsDropDownActive(true)}
          onMouseLeave={() => setIsDropDownActive(false)}
          className={classNames({
            [styles.MobileDropdown]: true,
            [styles.MobileDropdownActive]: isDropDownActive,
          })}
          ref={ref}
        >
          <div
            onClick={onPhoneClick}
            className={classNames({
              [styles.MobileDropdownWrapper]: true,
              [styles.MobileDropdownWrapperActive]: isDropDownActive,
            })}
          >
            <Image
              src={"/icons/phoneHeader.svg"}
              alt="address icon"
              width={24}
              height={24}
            />
            <div className={styles.MobileDropdownText}>+375 (44) 771-09-40</div>
            <Image
              className={classNames({
                [styles.Arrow]: true,
                [styles.ArrowActive]: isDropDownActive,
              })}
              src={"/icons/arrowHeader.svg"}
              alt="address icon"
              width={24}
              height={24}
            />
          </div>

          {isDropDownActive && (
            <div className={styles.DropIconLinksWrapper}>
              <div className={styles.Dividing} />
              <a
                target="_blank"
                href="https://www.tiktok.com/@questminsk"
                className={styles.DropIconLink}
              >
                <Image
                  src={"/headerIcons/tt.svg"}
                  alt="address icon"
                  width={19}
                  height={19}
                />
                <span>Мы в Tik-Tok</span>
              </a>

              <a
                target="_blank"
                href="https://www.instagram.com/oldmouse.by/"
                className={styles.DropIconLink}
              >
                <Image
                  src={"/headerIcons/inst.svg"}
                  alt="address icon"
                  width={19}
                  height={19}
                />
                <span>Мы в Instagram</span>
              </a>

              <a
                target="_blank"
                href="https://vk.com/oldmouseminsk"
                className={styles.DropIconLink}
              >
                <Image
                  src={"/headerIcons/vk.svg"}
                  alt="address icon"
                  width={19}
                  height={19}
                />
                <span>Мы в VK</span>
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(HeaderWeb);
