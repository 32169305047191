import { memo, useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";
import PhoneIcon from "@material-ui/icons/Phone";
import ClearIcon from "@material-ui/icons/Clear";
import FormatListBulletedRoundedIcon from "@material-ui/icons/FormatListBulletedRounded";

import { headerConfig } from "utils/constants";
import { DividingLine } from "components";

import styles from "./HeaderMobile.module.scss";

const HeaderMobile = (props: any) => {
  const { isTabActive } = props;
  const router = useRouter();
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  useEffect(() => {
    if (isAccordionExpanded) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isAccordionExpanded]);

  const handleClick = (href) => {
    router.push(href);
    setIsAccordionExpanded(false);
  };

  return (
    <section>
      <div className={"flex justify-between h-12 w-full mt-3"}>
        <div className={"flex items-center"}>
          <Link href="/">
            <Image
              className={"cursor-pointer"}
              src="/icons/logomouse.svg"
              alt="Oldmouse logo"
              width={"100%"}
              height={30}
            />
          </Link>
        </div>

        <div className="flex flex-row">
          <section className={"flex items-center justify-center"}>
          <div className={styles.PhoneIcon}>
            <a href="tel:+375447710940">
              <Image
                src={"/icons/phoneHeader.svg"}
                alt="address icon"
                width={20}
                height={20}
              />
            </a>
          </div>
            <div className={"flex items-center justify-center rounded "}>
              {isAccordionExpanded ? (
                <ClearIcon
                  onClick={() => setIsAccordionExpanded(false)}
                  style={{ width: "25px", height: "25px" }}
                  className={"text-white"}
                />
              ) : (
                <div onClick={() => setIsAccordionExpanded(true)}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 12H21M3 6H21M3 18H21"
                      stroke="#CAC6CE"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      {isAccordionExpanded && (
        <div className={styles.Droprodwn}>
          <div className={"flex justify-between h-12 w-full mt-3"}>
            <div className={"flex items-center"}>
              <Link href="/">
                <Image
                  className={"cursor-pointer"}
                  src="/icons/logomouse.svg"
                  alt="Oldmouse logo"
                  width={"100%"}
                  height={30}
                />
              </Link>
            </div>

            <div className="flex flex-row">
              <section className={"flex items-center justify-center"}>
                <div className={"flex items-center justify-center rounded "}>
                  {isAccordionExpanded ? (
                    <ClearIcon
                      onClick={() => setIsAccordionExpanded(false)}
                      style={{ width: "25px", height: "25px" }}
                      className={"text-white"}
                    />
                  ) : (
                    <div onClick={() => setIsAccordionExpanded(true)}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 12H21M3 6H21M3 18H21"
                          stroke="#CAC6CE"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
          <div className={styles.DropdownItems}>
            {headerConfig.map((item) => (
              <div key={item.label} onClick={() => handleClick(item.href)}>
                <section
                  className={classNames({
                    "cursor-pointer my-2.5": true,
                    "text-white border-b-2 w-max border-red-600":
                      isTabActive(item),
                  })}
                >
                  {item.label}
                </section>
              </div>
            ))}
          </div>

          <a href="tel:+375447710940">
            <div
              className={classNames({
                [styles.MobileDropdownWrapper]: true,
              })}
            >
              <Image
                src={"/icons/phoneHeader.svg"}
                alt="address icon"
                width={24}
                height={24}
              />
              <div className={styles.MobileDropdownText}>
                +375 (44) 771-09-40
              </div>
            </div>
          </a>
          <div className={styles.DropIconLinksWrapper}>
            <div
              onClick={() => router.push("https://www.tiktok.com/@questminsk")}
              className={styles.DropIconLink}
            >
              <Image
                src={"/headerIcons/tt.svg"}
                alt="address icon"
                width={19}
                height={19}
              />
              <span>Мы в Tik-Tok</span>
            </div>

            <div
              onClick={() =>
                router.push("https://www.instagram.com/oldmouse.by/")
              }
              className={styles.DropIconLink}
            >
              <Image
                src={"/headerIcons/inst.svg"}
                alt="address icon"
                width={19}
                height={19}
              />
              <span>Мы в Instagram</span>
            </div>

            <div
              onClick={() => router.push("https://vk.com/oldmouseminsk")}
              className={styles.DropIconLink}
            >
              <Image
                src={"/headerIcons/vk.svg"}
                alt="address icon"
                width={19}
                height={19}
              />
              <span>Мы в VK</span>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default memo(HeaderMobile);
