import { memo } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";

import styles from "./SubHeader.module.scss";

import { PageWrapper } from "components";
import { urlNames } from "utils/constants";

const SubHeader = () => {
  const router = useRouter();
  const path = router.asPath;
  const item = urlNames.find(c => path.includes(c.path));

  return (
    <>
      {item && item.name && path.length > 1 &&
        <PageWrapper>
          <div className={styles.SubHeader}>
            <div className={styles.BreadCrumbs}>
              <Link href="/">
                <a className="cursor: pointer">
                  Квесты
                </a>
              </Link>
              <div className={styles.ArrowWithCrumb}>
                <div className={styles.Arrow}>
                  <Image
                    src="/icons/arrow.svg"
                    alt="Oldmouse logo"
                    width={15}
                    height={15}
                  />
                </div>
                <div className={styles.Crumb}>{item.name}</div>
              </div>
            </div>
          </div>
        </PageWrapper>
      }
    </>
  );
};

export default memo(SubHeader);
