import React, { memo, useCallback, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import FaceIcon from '@material-ui/icons/Face';
import PhoneIcon from '@material-ui/icons/Phone';
import MessageIcon from '@material-ui/icons/Message';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

import { Button } from 'components';
import classNames from 'classnames';

const styles = makeStyles((theme: Theme,) => createStyles({
  input: {
    padding: '12px 12px 12px 0',
    width: '100%',
    ['@media (max-width:1020px)']: {
      padding: '12px 0',
    },
    '&::placeholder': {
      opacity: 1,
      color: 'white',
      fontWeight: 500,
      letterSpacing: '0.025em',
    },
    '& label': {
      fontSize: '14px',
      color: '#805FAA',
    },
    '&:hover label': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiInputBase-input': {
        padding: '12px 16px',
        borderRadius: '10px',
      },
      color: 'white',
      '& fieldset': {
        transition: '0.3s',
        borderColor: '#805FAA',
        borderWidth: '1px',
        borderRadius: '10px',
      },
      '&:hover fieldset': {
        borderColor: '#CAC6CE',
        borderWidth: '1px',
        borderRadius: '10px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CAC6CE',
        borderWidth: '1px',
        borderRadius: '10px',
      },
    },
  },
  select: {
    padding: '12px 12px 12px 0',
    width: '100%',
    ['@media (max-width:1020px)']: {
      padding: '12px 0',
    },
    '&::placeholder': {
      opacity: 1,
      color: 'white',
      fontWeight: 500,
      letterSpacing: '0.025em',
    },
    '& label': {
      color: '#805FAA',
    },
    '& .MuiSelect-icon': {
      color: '#805FAA',
    },
    '&:hover label': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiInputBase-input': {
        padding: '12px 16px',
        borderRadius: '10px',
      },
      color: 'white',
      '& fieldset': {
        transition: '0.3s',
        borderColor: '#805FAA',
        borderWidth: '1px',
        borderRadius: '10px',
      },
      '&:hover fieldset': {
        borderColor: '#CAC6CE',
        borderWidth: '1px',
        borderRadius: '10px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CAC6CE',
        borderWidth: '1px',
        borderRadius: '10px',
      },
    },
  },
  disabled: {
    padding: '12px 12px 12px 0',
    width: '100%',
    ['@media (max-width:1020px)']: {
      padding: '12px 0',
    },
    '& label': {
      color: '#3f464a !important',
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiInputBase-input': {
        padding: '12px 16px',
      },
      color: '#3f464a',
      '& fieldset': {
        borderColor: '#3f464a !important',
      },
    },
  },
  isIframe: {
    padding: '12px 12px 12px 0',
    width: '100%',
    ['@media (max-width:1020px)']: {
      padding: '12px 0',
    },
    '&::placeholder': {
      color: '#5B7280',
    },
    '& label': {
      color: '#545e64',
    },
    '&:hover label': {
      color: '#5B7280',
    },
    '& label.Mui-focused': {
      color: '#5B7280',
    },
    '& .MuiOutlinedInput-root': {
      color: '#5B7280',
      '& fieldset': {
        borderColor: '#545e64',
      },
      '&:hover fieldset': {
        borderColor: '#5B7280',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#5B7280',
      },
    },
  },
}))

const CustomInput = (props: any) => {
  const classes = styles()

  const {
    type = 'text',
    label,
    placeholder,
    name,
    isInvalid,
    setIsInvalid,
    onChange,
    required = true,
    isValid,
    helperText,
    options,
    buttonName,
    onCLick,
    disabled,
    value,
  } = props;

  const [
    errorText, setErrorText,
  ] = useState<string>('',)

  const onBlur = useCallback((event,) => {
    if (!!isValid && type !== "select") {
      const valid = isValid(event.target.value,)
      // setIsInvalid(!valid,)
      // setErrorText(valid ? '' : helperText,)
    }
  }, [
    isValid,
    setIsInvalid,
    setErrorText,
  ])

  return (
    <form className={'w-full'} noValidate>
      <TextField
        id="outlined-adornment-input"
        className={classNames({
          'flex-shrink-0': true,
          [classes.select]: type === "select" && !disabled,
          [classes.input]: type !== "select" && !disabled,
          [classes.disabled]: disabled,
          [classes.isIframe]: props?.isIframe,
        })}
        label={label}
        name={name}
        fullWidth={true}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        value={value}
        select={type === "select"}
        autoComplete={'off'}
        variant={'outlined'}
        onBlur={onBlur}
        SelectProps={{
          'native': true,
        }}
        onChange={onChange}
        required={required}
        helperText={errorText}
        error={isInvalid}
        InputProps={{
          classes: { input: classes.input, },
          // startAdornment: type !== "select" && (
          //   <InputAdornment position="start">
          //     {
          //       {
          //         phone: <PhoneIcon />,
          //         phoneNumber: <PhoneIcon />,
          //         email: <AlternateEmailIcon />,
          //         name: <FaceIcon />,
          //         search: <SearchIcon />,
          //         comment: <MessageIcon />,
          //       }[name] || <TextFieldsIcon />
          //     }
          //   </InputAdornment>
          // ),
          endAdornment: buttonName && (
            <div className={'h-full'} onClick={onCLick}>
              <Button {...{
                colorName: 'secondary',
                disabled: false,
              }}>
                {buttonName}
              </Button>
            </div>
          ),
        }}
      >
        {!!options && options?.map((option) => (
          <option 
            key={option.value || option.countOfPlayers} 
            value={option.value || option.countOfPlayers}
            className="text-black"
          >
            {option.label || option.countOfPlayers}
          </option>
        ))}
      </TextField>
    </form>
  )
}

export default memo(CustomInput);