import { createStyles, makeStyles, Theme, } from '@material-ui/core/styles';

export const styles = makeStyles((theme: Theme,) => createStyles({
  select: {
    padding: '12px 12px 12px 0',
    ['@media (max-width:1020px)']: {
      padding: '12px 0',
    },
    width: '100%',
    '& label': {
      color: '#545e64',
    },
    '&:hover label': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& label.Mui-disabled': {
      color: '#545e64',
    },
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& fieldset': {
        borderColor: '#545e64',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#545e64',
      },
      '& .MuiSelect-icon': {
        color: '#805FAA',
      },
      '& .MuiSelect-root option': {
        backgroundColor: 'black',
      },
    },
  },
}))