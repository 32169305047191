import React, { memo, PropsWithChildren } from "react";
import { makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Rating from "@material-ui/lab/Rating";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import { ShowMoreShowLess } from "../..";
import getNameFirstLetters from "../../../src/utils/getNameFirstLetters";

import { CircularProgressbar } from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";

import styles from "./Review.module.scss";
import Image from "next/image";

const useStyles = makeStyles((theme: Theme) => ({
  bubble: {
    backgroundColor: "#343A40",
    padding: "1.125em 1.5em",
    marginTop: ".5em",
    fontSize: "1em",
    borderRadius: "1rem",
    boxShadow:
      "0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2)",
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    padding: ".25rem",
    marginRight: ".5rem",
  },
}));

const StyledRating = withStyles({
  iconFilled: {
    color: "#FF0D40",
  },
  iconEmpty: {
    color: "#556267",
  },
})(Rating);

interface ReviewProps extends PropsWithChildren<any> {
  name: string;
  text: string;
  date: string;
  overallRating: number;
}

const Review = (props: ReviewProps) => {
  const {
    name,
    text,
    date,
    overallRating,
    questName,
    isSingleReviewsView,
    galochka,
  } = props;

  return (
    <div className={styles.QuestCard}>
      <div className={styles.QuestCardNewWrapper}>
        <div className={styles.QuestContainerText}>
          <div className={styles.QuestContainerTextWrap}>
            <div className={styles.QuestCardImage}>{name}</div>
            <div className={styles.QuestCardReviewDate}>{date}</div>
          </div>

          <div className={styles.pieMobile}>
            <CircularProgressbar
              value={overallRating}
              maxValue={10}
              strokeWidth={4}
              text={`${overallRating}`}
              styles={{
                path: {
                  width: "2px",
                  stroke: `#6A4993`,
                  strokeLinecap: "butt",
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  transformOrigin: "center center",
                },
                trail: {
                  stroke: "#2A1544",
                },
                text: {
                  fill: "white",
                  fontSize: "26px",
                },
              }}
            />
          </div>
        </div>

        <div className={styles.Questinfo}>
          <div className={styles.QuestInfoBlock}>
            <div className={styles.QuestinfoDescription}>{text}</div>
          </div>
        </div>
      </div>

      <div className={styles.pie}>
        <CircularProgressbar
          value={overallRating}
          maxValue={10}
          strokeWidth={4}
          text={`${overallRating}`}
          styles={{
            path: {
              width: "2px",
              stroke: `#6A4993`,
              strokeLinecap: "butt",
              transition: "stroke-dashoffset 0.5s ease 0s",
              transformOrigin: "center center",
            },
            trail: {
              stroke: "#2A1544",
            },
            text: {
              fill: "white",
              fontSize: "26px",
            },
          }}
        />
      </div>
    </div>
  );
};

export default memo(Review);
