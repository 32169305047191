import React, { memo } from "react";
import { createStyles, Theme, withStyles, } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from './QuestStatistic.module.scss';

const QuestStatistic = ({ statisticFirstData }) => {
  const BorderLinearProgress = withStyles((theme: Theme,) => createStyles({
    root: {
      height: 9,
      width: '300px',
      borderRadius: 5,
      '@media (max-width: 720px)': {
        width: '90vw'
      }
    },
    colorPrimary: {
      backgroundColor: '#2A1544',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#805FAA',
    },
  }))(LinearProgress);

  return (
    <div className=" sm:mt-6">
      <div className={'w-full flex flex-row sm:flex-col items-start h-10 sm:h-auto mb-2'}>
        <p className={styles.Title}>Общее впечатление <span>({statisticFirstData.averageGeneralImpressionRating} / 10)</span></p>
        <div className={styles.LineWrapper}>
        <BorderLinearProgress variant="determinate" value={statisticFirstData.averageGeneralImpressionRating * 10} />
        <span>{statisticFirstData?.averageGeneralImpressionRating ? statisticFirstData.averageGeneralImpressionRating : 0} / 10 </span> 
        </div>
      </div>
      <div className={'w-full flex flex-row sm:flex-col items-start h-10 sm:h-auto mb-2'}>
        <p className={styles.Title}>Сюжет и задания <span>({statisticFirstData.averagePlotAndTaskRating} / 10)</span></p>
        <div className={styles.LineWrapper}>
        <BorderLinearProgress variant="determinate" value={statisticFirstData.averagePlotAndTaskRating * 10} />
        <span>{statisticFirstData.averagePlotAndTaskRating ? statisticFirstData.averagePlotAndTaskRating : 0} / 10</span>
        </div>
      </div>
      <div className={'w-full flex flex-row sm:flex-col items-start h-10 sm:h-auto mb-2'}>
        <p className={styles.Title}>Персонал <span>({statisticFirstData.averageActorsRating} / 10)</span></p>
        <div className={styles.LineWrapper}>
        <BorderLinearProgress variant="determinate" value={statisticFirstData.averageActorsRating * 10} />
        <span>
        {statisticFirstData.averageActorsRating ? statisticFirstData.averageActorsRating : 0} / 10
        </span>
        </div>

      </div>
      <div className={'w-full flex flex-row sm:flex-col items-start h-10 sm:h-auto mb-2'}>
        <p className={styles.Title}>Атмосфера <span>({statisticFirstData.averageAtmosphereRating} / 10)</span></p>
        <div className={styles.LineWrapper}>
        <BorderLinearProgress variant="determinate" value={statisticFirstData.averageAtmosphereRating * 10} />
        <span>{statisticFirstData.averageAtmosphereRating ? statisticFirstData.averageAtmosphereRating : 0} / 10</span>
        </div>
      </div>
    </div>
  );
};

export default memo(QuestStatistic);