import axios from 'axios';

export const baseGetRequest = async (requestUrl) => {
  const response = await axios.get(requestUrl);

  return response.data;
};

export const basePostRequest = async (requestUrl, body) => {
  const response = await axios.post(requestUrl, body);

  return response.data;
};

export const baseDeleteRequest = async (requestUrl, body) => {
  const response = await axios.delete(requestUrl, body);

  return response.data;
};
