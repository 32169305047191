import React from "react";
import classNames from "classnames";

const PaginationButton = ({paginate, children, isSelected = false}) => (
  <button
    type="button"
    onClick={paginate}
    className={classNames("admissions-pagination__item", {
      'admissions-pagination__item_selected': isSelected,
    })}
  >
    {children}
  </button>
)

export default PaginationButton
