import { memo } from "react";
import classNames from "classnames";
import styles from "./HtmlRenderer.module.scss";

const HtmlRenderer = (props: any) => {
  const { genresText, page } = props;

  return (
    <div
      className={classNames({
        [styles.TextComponent]: true,
        [styles.TextComponentBgMain]: true,
        [styles.TextComponentBgRaiting]: page === "raiting",
        [styles.TextComponentBgReviews]: page === "reviews",
        [styles.TextComponentBgSkidki]: page === "skidki",
        [styles.TextComponentBgContacts]: page === "contacts",
      })}
    >
      <div className={styles.TextComponentTextWrapper}>
        <h1 className={styles.TextComponentTextTitle}>{genresText.h1Text}</h1>

        <br />

        <div className={"flex flex-col text-white text-sm"}>
          {genresText.description.map((text) => (
            <span key={text} className={"mb-4"}>
              {text}
            </span>
          ))}

          {genresText?.h2Text && (
            <h2 className={"pb-2 text-lg font-semibold"}>
              {genresText.h2Text}
            </h2>
          )}
          {genresText?.list?.title && <span>{genresText.list.title}</span>}

          {!!genresText?.list?.items.length && (
            <ul className={styles.ul}>
              {genresText?.list?.items?.map((text) => (
                <li key={text}>{text}</li>
              ))}
            </ul>
          )}

          {!!genresText.secondListText?.length &&
            genresText.secondListText.map((text) => (
              <span key={text} className={"mb-4"}>
                {text}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default memo(HtmlRenderer);
