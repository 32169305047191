import PaginationButton from 'components/Molecules/PaginationButton';
import PaginationSplit from 'components/Molecules/PaginationSplit';

const Pagination = ({currentPage, totalPages, onPaginate}) => {
  const paginate = (pageNumber) => () => {
    if (pageNumber === currentPage) return

    onPaginate(pageNumber)
  }

  const getPagesFromCount = (pagesCount) => Array.from(Array(pagesCount).keys()).map(value => value + 1);

  return totalPages > 1 ? (
    <div className="admissions-pagination">
      {currentPage !== 1 ? (
        <PaginationButton paginate={paginate(currentPage - 1)}>
           {"<"}
        </PaginationButton>
      ) : null}
      {totalPages <= 7 ? getPagesFromCount(totalPages).map((pageNumber) => (
        <PaginationButton
          key={pageNumber}
          paginate={paginate(pageNumber)}
          isSelected={pageNumber === currentPage}
        >
          {pageNumber}
        </PaginationButton>
      )) : (
        <PaginationSplit currentPage={currentPage} paginate={paginate} totalPages={totalPages}/>
      )}
      {currentPage !== totalPages ? (
        <PaginationButton paginate={paginate(currentPage + 1)}>
           {">"}
        </PaginationButton>
      ) : null}
    </div>
  ) : null
}

export default Pagination