import React, { memo } from "react";

import Image from "next/image";
import styles from "./QuestInfo.module.scss";

const QuestInfo = (props: any) => {
  const {
    genres,
    withActors,
    horror,
    duration,
    countOfPlayersInfo,
    ageInfo,
    language,
    difficulty,
    address,
  } = props.questFirstData;

  const infoItems = [
    { icon: "/quizIcons/genre.svg", label: "Жанр:", value: genres },
    { icon: "/quizIcons/clock.svg", label: "Время игры:", value: duration },
    {
      icon: "/quizIcons/players.svg",
      label: "Количество игроков:",
      value: countOfPlayersInfo + " игроков",
    },
    { icon: "/quizIcons/age.svg", label: "Возраст:", value: ageInfo },
    {
      icon: "/quizIcons/actors.svg",
      label: "С актером:",
      value: withActors ? "Да" : "Нет",
    },
    { icon: "/quizIcons/language.svg", label: "Языки:", value: language },
    { icon: "/quizIcons/location.svg", label: "Адрес:", value: address },
    { icon: "/quizIcons/car.svg", label: "Парковка:", value: "Есть" },
    { icon: "/quizIcons/wifi.svg", label: "Wi-Fi:", value: "Есть" },
  ];

  return (
    <div className={"flex flex-col justify-center w-full "}>
      {infoItems.map((item) => (
        <div key={`info-${item.label}`} className={"mt-4 flex"}>
          <div className={styles.ImagesWrapper}>
          <Image src={item.icon} alt="address icon" width={20} height={20} />
          </div>
          <span className={styles.Label}>{item.label + " "}</span>
          <span className={styles.Value}>
            {Array.isArray(item.value)
              ? item.value.map((item) => item.genre + ", ")
              : item.value}
          </span>
        </div>
      ))}
    </div>
  );
};

export default memo(QuestInfo);
