import React, { memo } from "react";
import Image from "next/image";
import Link from "next/link";

import styles from "./Footer.module.scss";

import { PageWrapper } from "components";

import { Cookies } from "react-cookie-consent";

const Footer = () => {
  const icons = [
    {
      src: "/contactsIcons/instagram.svg",
      href: "https://www.instagram.com/oldmouse.by",
      alt: "Insta logo",
      width: 42,
      height: 42,
      visible: true,
    },
    {
      src: "/contactsIcons/tiktok.svg",
      href: "https://www.tiktok.com/@questminsk",
      alt: "TT logo",
      width: 42,
      height: 42,
      visible: true,
    },
    {
      src: "/contactsIcons/vk.svg",
      href: "https://vk.com/oldmouseminsk",
      alt: "VK logo",
      width: 42,
      height: 42,
      visible: true,
    },
  ];

  const handleReopenCookiesModal = () => {
    Cookies.remove("CookieConsent");
    window.location.reload();
  };

  return (
    <div className={styles.FooterWrapper}>
      <PageWrapper>
        <div className={styles.FooterContainer}>
          <div className="flex flex-col items-start flex-shrink-0">
            <p className={styles.FooterText}>
              ООО "ОЛД МАУС" УНП 193663622 Old Mouse
            </p>
            <p className={styles.FooterTextSecond}>
              <Link href={"/polzovatelskoe-soglashenie"} scroll={true}>
                <a className={"hover:underline"}>Пользовательское соглашение</a>
              </Link>
            </p>
            <p className={styles.FooterTextSecond}>
              <Link href={"/privacy-policy"} scroll={true}>
                <a className={"hover:underline"}>Политика конфиденциальности</a>
              </Link>
            </p>
            <p className={styles.FooterTextSecond}>
              <Link href={"/politika-obrabotki-cookies"} scroll={true}>
                <a className={"hover:underline"}>Политика обработки cookies</a>
              </Link>
            </p>
            <p className={styles.FooterTextSecond}>
              <span
                className={"hover:underline cursor-pointer"}
                onClick={handleReopenCookiesModal}
              >
                Настройки cookies
              </span>
            </p>
          </div>

          <div className="flex flex-row justify-end items-center flex-shrink-0 sm:mt-6">
            {icons.map(
              (item) =>
                item?.visible && (
                  <Link href={item.href} key={item.src}>
                    <a className={styles.ImageIcon}>
                      <Image
                        src={item.src}
                        alt={item.alt}
                        width={item.width}
                        height={item.height}
                      />
                    </a>
                  </Link>
                )
            )}
          </div>
        </div>
      </PageWrapper>
    </div>
  );
};

export default memo(Footer);
