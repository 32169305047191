const baseUrl = `${process.env.API_HOST}${process.env.API_VERSION}`;

export const quizListUrl = (activeCategoryId: any, search: any) => {
  const queryParams = [
    'filter.activeFlag=true',
    'includeTodayEvents=true',
    'includeGenres=true',
    'includeQuestPrices=true',
  ];

  if (activeCategoryId) {
    queryParams.push(`filter.genreId=${activeCategoryId}`,)
  };

  if (search) {
    queryParams.push(`filter.name=%${search}%`,)
  };

  if (queryParams.length === 0) {
    return ''
  };

  return `${baseUrl}/quests/page?${encodeURI(queryParams.reduce((accumulator = '', currentValue,) => `${accumulator}&${currentValue}`,),)}`
};

export const genresUrl = `${baseUrl}/quests/genres`;
export const quizListRaitingUrl = `${baseUrl}/quests/averageOverallRating`;
export const allReviewsListUrl = `${baseUrl}/quests/reviews/page?filter.accepted=true&size=50`;
export const saveClientUrl = `${baseUrl}/clients/saveOrUpdate`;
export const sertificateUrl = `${baseUrl}/certificates`;
export const sendCodeUrl = `${baseUrl}/order/code/save `;
export const checkCodeUrl = `${baseUrl}/order/code/check `;
export const questReviewUrl = `${baseUrl}/quests/reviews `;
export const getNewsUrl = `${baseUrl}/news/page`;

export const singleQuestUrl = (id: any) => `${baseUrl}/quests/${id}?includeGenres=true&includeQuestPrices=true&includeImages=true`;
export const questEventsListUrl = (id: any) => `${baseUrl}/quests/events/page?filter.questId=${id}&sort=startDateTime&size=4000`;
export const reviewsListUrl = (id: any) => `${baseUrl}/quests/reviews/page?filter.accepted=true&filter.questId=${id}`;
export const statisticListUrl = (id: any) => `${baseUrl}/quests/${id}/statistic`;
export const questReviewsListUrl = (id: any) => `${baseUrl}/quests/reviews/page?filter.accepted=true&filter.questId=${id}`;
export const questBookingUrl = (id: any) => `${baseUrl}/quests/events/${id}/noApprove/order`;
export const questRaitingUrl = (id: any) => `${baseUrl}/quests/${id}/averageOverallRating`;


export const adminGetUsers = (filter: any) => {
  if (!filter) {
    return '';
  }
  const {
    role,
    questEventId,
    email = '',
  } = filter;

  const queryParams: Array<string> = [
  ];

  if (role) {
    queryParams.push(`filter.role=${role}`,);
  }

  if (questEventId) {
    queryParams.push(`filter.questEventId=${questEventId}`,);
  }

  if (email) {
    queryParams.push(`filter.email=%${email}%`,);
  }

  if (queryParams.length === 0) {
    return '';
  }

  return `users/page?${encodeURI(queryParams.reduce((accumulator = '', currentValue,) => `${accumulator}&${currentValue}`,),)}`;
}
