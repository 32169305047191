import { memo, useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import TextFieldsIcon from "@material-ui/icons/TextFields";

import { Button, CustomInput } from "components";

import styles from "./PhoneConfirmation.module.scss";

import PinCodeInput from "components/Molecules/PinCodeInput";

const PhoneConfirmation = (props: any) => {
  const {
    setIsPhoneConfirmedModalOpen,
    setIsPhoneConfirmed,
    classes,
    code,
    setCode,
    sendCode,
  } = props;

  const [seconds, setSeconds] = useState(60);

  const initDigits = ["", "", "", "", "", ""];
  const [digits, setDigits] = useState(initDigits);

  useEffect(() => {
    setCode(digits.join(''));
  }, digits)

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  });

  const sendNewCode = () => {
    if (seconds <= 0) {
      sendCode();
      setSeconds(60);
    };
  };

  return (
    <div className="flex flex-col w-full">
      <div className={styles.Title}>Бронирование квеста</div>

      <div className={styles.Text}>
        На ваш номер телефона был отправлен код подтверждения. Введите его ниже,
        чтобы закончить бронирование
      </div>

      <PinCodeInput digits={digits} changeHandler={setDigits} />

      <div className={styles.Time}>
        Отправить повторно через <span> {seconds} сек</span>
      </div>

      <div onClick={sendNewCode} className={styles.Send}>Отправить код еще раз</div>

      <div onClick={() => setIsPhoneConfirmedModalOpen(false)} className={styles.Change}>Изменить данные</div>
    </div>
  );
};

export default memo(PhoneConfirmation);
