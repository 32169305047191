import React, { memo, PropsWithChildren } from 'react';

import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const CustomButton = withStyles( {
  root: {
    width: '100%',
    boxShadow: 'none',
    outline: '0 none !important',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    borderColor: '#FF0D40',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join( ',', ),
  },
  containedPrimary: {
    backgroundColor: '#FF0D40',
    '&:hover': {
      transition: 'background-color 0.5s ease',
      textColor: 'black',
      backgroundColor: '#DC3545',
      borderColor: '#DC3545',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none !important',
      outline: '0 none !important',
      backgroundColor: '#DC3545',
      borderColor: '#DC3545',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(255, 0, 123,.5)',
    },
    '&:disabled': {
      color: '#6a7276',
      backgroundColor: '#633035',
      borderColor: '#633035',
    },
  },
  containedSecondary: {
    backgroundColor: '#080809',
    borderColor: '#F8F9FA',
    outline: '0 none !important',
    '&:hover': {
      transition: 'color 0.8s ease, background-color 0.5s ease',
      color: 'black',
      backgroundColor: '#F8F9FA',
      boxShadow: 'none !important',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#F8F9FA',
      borderColor: '#808181',
      outline: '0 none !important',
    },
    '&:focus': {
      outline: '0 none !important'
    },
    '&:disabled': {
      color: '#494F54',
      borderColor: '#494F54',
    },
  },
}, )( Button )

const useStyles = makeStyles( ( theme: Theme, ) => createStyles( {
  margin: {
    marginTop: theme.spacing( 1, ),
    marginBottom: theme.spacing( 1, ),
    outline: '0 none !important',
  },
}, ), )

interface ButtonProps extends PropsWithChildren<any> {
  colorName?: any,
  disabled: boolean,
  type?: 'button' | 'submit' | 'reset',
}

export default memo( function Button( props: ButtonProps, ) {
  const {
    colorName,
    disabled,
    type = 'button',
    children,
  } = props

  const classes = useStyles()

  return (
    <>
      <CustomButton variant="contained" type={type} color={colorName} disabled={disabled} className={classes.margin}>
        {children}
      </CustomButton>
    </>
  );
}, );
