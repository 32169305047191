import { memo } from "react";

import { FilterCard } from "components";

import styles from "./MainPageFilters.module.scss";

const MainPageFilters = (props: any) => {
  const {
    genresFirstData,
    onCategoryClick,
    setItem,
    activeGenre,
    playersCount,
    withActor,
  } = props;

  const newGenresFirstData = [{ id: 0, value: "Любой" }, ...genresFirstData];

  const playersCountArr = [
    { id: "playersCountArr", value: 'Любое', action: "all" },
    { id: "playersCountArr", value: 2, action: 2 },
    { id: "playersCountArr", value: 3, action: 3 },
    { id: "playersCountArr", value: 4, action: 4 },
    { id: "playersCountArr", value: 5, action: 5 },
    { id: "playersCountArr", value: 6, action: 6 },
  ];

  const actorArr = [
    { id: "actorArr", value: "Неважно", action: "all" },
    { id: "actorArr", value: "С актером", action: true },
    { id: "actorArr", value: "Без актера", action: false },
  ];

  return (
    <div className={styles.MainPageFilters}>
      <FilterCard
        title="Жанр"
        value={activeGenre?.[0]?.genre || "Любой"}
        dropdownArr={newGenresFirstData}
        onItemClick={setItem}
      />
      <FilterCard
        title="Количество игроков"
        value={playersCount || "Любое"}
        dropdownArr={playersCountArr}
        onItemClick={setItem}
      />
      <FilterCard
        title="Актер"
        value={
          withActor !== null
            ? withActor
              ? "С актером"
              : "Без актера"
            : "Неважно"
        }
        dropdownArr={actorArr}
        onItemClick={setItem}
      />
      {/* <FilterCard
        title="Сложность"
        value="Любая"
        dropdownArr={difficultyArr}
        onItemClick={setItem}
      /> */}
      {/* <FilterCard
        title="Сортировать по"
        value="Неважно"
        dropdownArr={sortByArr}
        onItemClick={setItem}
      /> */}
    </div>
  );
};

export default memo(MainPageFilters);
