import React, { memo, PropsWithChildren, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import styles from "./DialogWindow.module.scss";

const DialogWindow = (props: any) => {
  const { title, isOpen, setOpen, maxWidth, children, subTitle } = props;

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'scroll'
    }
  }, [])

  return (
    <div className={styles.ModalWrapper}>
      <div className={styles.ModalCard}>{children}</div>
    </div>
  );
};

export default memo(DialogWindow);
