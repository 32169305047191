import React, { memo, PropsWithChildren } from "react";
import { Button, DialogWindow } from "components";

import styles from "./ModalForm.module.scss";
import classNames from "classnames";

export default memo(function ModalForm(props: any) {
  const {
    modalTitle,
    isOpen,
    setOpen,
    onSubmit,
    isFormInvalid,
    submitButtonLabel,
    modalWidth,
    children,
    subTitle,
    isShowPrivacyPolice,
  } = props;

  return (
    <>
      <DialogWindow>
        <form className={"flex flex-shrink-0 flex-col"}>
          {children}
          {isShowPrivacyPolice && (
            <div
              className={`w-full ${
                props?.isIframe ? "text-white" : "text-white"
              }  text-xs sm:text-xs my-2`}
            >
              Оставляя заявку вы соглашаетесь на обработку персональных данных в
              соответствии с{" "}
              <a href="/privacy-policy">
                <span className={"cursor-pointer mt-2  underline"}>
                  политикой конфиденциальности
                </span>
              </a>
            </div>
          )}
          <div
            className={
              "flex flex-shrink-0  sm:flex-col md:items-center sm:items-center justify-between mt-4 mb-2 w-full"
            }
          >
            {submitButtonLabel && (
              <div
                onClick={() => {
                  if (!isFormInvalid) {
                    onSubmit();
                    // setOpen(!isOpen,)
                  }
                }}
                className={classNames({
                  [styles.SubmitButton]: true,
                  [styles.SubmitButtonDisabled]: isFormInvalid,
                })}
              >
                {submitButtonLabel}
              </div>
            )}
            <div
              onClick={() => setOpen(!isOpen)}
              className={classNames({
                [styles.CloseButton]: true,
                [styles.CloseButtonSingle]: !submitButtonLabel,
              })}
            >
              {!submitButtonLabel ? "Супер! Всё понятно" : "Закрыть"}
            </div>
          </div>
        </form>
      </DialogWindow>
    </>
  );
});
