import React, { memo, useState, } from 'react'
import ruLocale from 'date-fns/locale/ru'
import DateFnsUtils from '@date-io/date-fns'
import { isValid, isAfter, isBefore, } from 'date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers'
import { MuiPickersOverrides, } from '@material-ui/pickers/typings/overrides'
import { createStyles, makeStyles, Theme, } from '@material-ui/core/styles'
import { createTheme, } from '@material-ui/core'
import { ThemeProvider, } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import dayjs from 'dayjs'

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {
  }
}

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#805FAA',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: '#CAC6CE',
      },
      iconButton: {
        backgroundColor: '#CAC6CE',
      },
    },
    // @ts-ignore
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: '#805FAA',
      },
    },
    MuiPickersDay: {
      day: {
        color: 'white',
      },
      daySelected: {
        backgroundColor: '#805FAA',
      },
      dayDisabled: {
        color: 'gray',
      },
      current: {
        color: '#F00C3C',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: '#CAC6CE',
        },
      },
    },
  },
})

const styles = makeStyles((theme: Theme,) => createStyles({
  input: {
    padding: '12px 12px 12px 0',
    ['@media (max-width:1020px)']: {
      padding: '12px 0',
    },
    width: '100%',
    '& label': {
      color: '#805FAA',
    },
    '&:hover label': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& fieldset': {
        transition: '0.3s',
        borderColor: '#805FAA',
        borderWidth: '1px',
        borderRadius: '10px',
      },
      '&:hover fieldset': {
        borderColor: '#CAC6CE',
        borderWidth: '1px',
        borderRadius: '10px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CAC6CE',
        borderWidth: '1px',
        borderRadius: '10px',
      },
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '2px',
    },
  },
}))

interface DatePickerProps {
  label: string,
  isInvalid: any,
  setIsInvalid: any,
  setPickerData: any,
}

function customTextField(fieldProps,) {
  const classes = styles()
  return (
    <TextField {...fieldProps} className={`${classes.input} `} />
  )
}

const DatePicker = (props: DatePickerProps) => {
  const [
    selectedDate, handleDateChange,
  ] = useState<Date | null>(new Date(),)

  const minDate = new Date(2000, 1, 1,)

  const {
    label,
    setIsInvalid,
    setPickerData,
  } = props

  const onChange = (value,) => {
    handleDateChange(value,)
    if (isValid(value,)) {
      if (!isAfter(value, new Date(),) && !isBefore(value, minDate,)) {
        setIsInvalid(false,)
        setPickerData(dayjs(value,)
          .format('YYYY-MM-DD',))
      } else {
        setIsInvalid(true,)
      }
    } else {
      setIsInvalid(true,)
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      <ThemeProvider theme={materialTheme}>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          label={label}
          format="dd/MM/yyyy"
          maxDate={new Date()}
          minDate={minDate}
          value={selectedDate}
          InputAdornmentProps={{ position: 'start', }}
          onChange={onChange}
          invalidDateMessage="Неверный формат даты"
          maxDateMessage="Неверная дата: Вы ещё не могли сходить на квест"
          minDateMessage="Неверная дата: наших квестов ещё тогда не было"
          TextFieldComponent={customTextField}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default memo(DatePicker);
