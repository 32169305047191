function hasDiffLength( str:string, regex:RegExp, ) {
  const replacedStr = str.replace( regex, '', )

  return replacedStr.length !== str.length
}

export function isEmailValid( value: string, ): boolean {
  if ( !value ) {
    return false
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return hasDiffLength( value, emailRegex, )
}

export function isPhoneValid( value: string, ): boolean {
  if ( !value ) {
    return false
  }

  const phoneRegex = /^[+]375\d{9}$/g

  const valueMatch = value.match( phoneRegex, )

  if ( valueMatch ) {
    return true
  }
  return false
}

export function isNameValid( value: string, ): boolean {
  if ( !value ) {
    return false
  }
  return true
}
