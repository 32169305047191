// @ts-nocheck
import React, { memo, PropsWithChildren, useState, useRef, useEffect, } from 'react';
import styles from './ShowMoreShowLess.module.scss';

import { Theme, createStyles, makeStyles, } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme,) => createStyles({
  divider: {
    backgroundColor: '#393E43',
    width: '100%',
    marginTop: 15,
    marginBottom: 10,
  },
  showText: {
    '&:target': {
      display: 'none',
    },
  },
  loadingScreen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    height: 'calc(100vh - 144px - 73px)',
    padding: '0 10px'
  },
  text: {
    width: '100%',
    whiteSpace: 'pre-wrap',
  },
}))

interface ShowMoreShowLessTextProps extends PropsWithChildren<any> {
  className: string,
  mainText: string,
  linkText: string,
}

const ShowMoreShowLess = (props: ShowMoreShowLessTextProps) => {
  const textWrapperRef = useRef<HTMLDivElement>(null,)
  const textRef = useRef<HTMLParagraphElement>(null,)

  const classes = useStyles()

  const [
    readMore, setReadMore,
  ] = useState<boolean>(true,)
  const [
    showLink, setShowLink,
  ] = useState<boolean>(false,)

  useEffect(() => {
    // @ts-ignore
    const texth = textRef.current.offsetHeight
    // @ts-ignore
    const wrapperh = textWrapperRef.current.clientHeight
    if (texth > wrapperh) {
      setShowLink(true,)
    } else {
      setReadMore(false,)
    }
  }, [
    textRef.current, textWrapperRef.current,
  ])

  const {
    className,
    mainText,
    linkText,
  } = props

  return (
    <div {...{ className, }}>
      <div ref={textWrapperRef} className={readMore ? 'ellipsis-text' : 'ellipsis-text-shown'}>
        <pre ref={textRef} className={classes.text}>
          {mainText}
        </pre>
      </div>
      <a id={'showmorelink'} onClick={() => {
        setReadMore(!readMore,)
      }} className={(showLink ? 'cursor-pointer mt-2 text-yellow-300' : 'show-more-link')}>{readMore ? `${linkText}` : 'Свернуть'}</a>
    </div>
  );
};

export default memo(ShowMoreShowLess);
