//@ts-nocheck
import { memo, useState, useRef } from "react";
import classNames from "classnames";
import Image from "next/image";

import { useOutsideClick } from "utils/index";

import styles from "./FilterCard.module.scss";

const FilterCard = (props: any) => {
  const { title, value, dropdownArr, onItemClick } = props;

  const [isDropdownAcrive, setIsDropdownAcrive] = useState(false);

  const ref = useRef();

  useOutsideClick(ref, () => {
    setIsDropdownAcrive(false);
  });

  return (
    <div
      ref={ref}
      className={classNames({
        [styles.MainPageFiltersWrapper]: true,
        [styles.MainPageFiltersWrapperActive]: isDropdownAcrive,
      })}
      onClick={() => setIsDropdownAcrive((prevState) => !prevState)}
    >
      <div className={styles.MainPageFiltersContainer}>
        <Image
          src="/icons/filterIcon.svg"
          alt="address icon"
          width={20}
          height={21}
        />
        <div className={styles.MainPageFiltersTextWrapper}>
          <div className={styles.MainPageFiltersTextTitle}>{title}</div>
          <div className={styles.MainPageFiltersTextValue}>{value}</div>
        </div>
      </div>
      <div>
        <Image
          className={classNames({
            [styles.Arrow]: true,
            [styles.ArrowActive]: isDropdownAcrive,
          })}
          src="/icons/arrowBottom.svg"
          alt="address icon"
          width={17}
          height={17}
        />
      </div>

      {isDropdownAcrive && (
        <div className={styles.Dropdown}>
          <div className={styles.DropdownWrapper}>
            {dropdownArr.map((item) => (
              <div
                className={styles.DropdownItem}
                onClick={() => onItemClick(item)}
              >
                {item?.genre || item?.value}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(FilterCard);
