import { memo, useState } from "react";
import { useRouter } from "next/router";
import { CircularProgressbar } from "react-circular-progressbar";
import styles from "./ReviewsShowComponent.module.scss";

import Image from "next/image";

import { PageWrapper, TextComponent } from "components";

import { translit } from "utils";
import "react-circular-progressbar/dist/styles.css";

const ReviewsShowComponent = (props: any) => {
  const { reviewListJSON, isSingleReviewsView } = props;

  const router = useRouter();

  const [questCounts, setQuestCounts] = useState(6);

  const sortableQuestsSlice = reviewListJSON.slice(0, questCounts);

  return (
    <div className={styles.QuestContainer}>
      {sortableQuestsSlice?.map((item, index) => (
        <div className={styles.QuestCard}>
          <div className={styles.QuestCardNewWrapper}>
            <div className={styles.QuestContainerTextMobile}>
              <div className={styles.QuestContainerText}>
                <div className={styles.QuestCardImage}>{item.client.name}</div>
                <div className={styles.QuestCardReviewDate}>
                  {item.visitDate}
                </div>

                <div className={styles.QuestCardQuestName}>
                  <Image
                    src={
                      !item?.quest?.horror
                        ? "/icons/lock.svg"
                        : "/icons/horror.svg"
                    }
                    alt="address icon"
                    width={20}
                    height={20}
                  />
                  <span className={styles.QuestCardQuestNameText}>
                    {item.quest.name}
                  </span>
                </div>
                <div className={styles.QuestCardQuestNameSecond}>
                  <Image
                    src={"/icons/mask.svg"}
                    alt="address icon"
                    width={20}
                    height={20}
                  />
                  <span className={styles.QuestCardQuestNameText}>
                    {item.quest.withActors ? "С актером" : "Без актера"}
                  </span>
                </div>
              </div>
              <div className={styles.pieMobile}>
                <CircularProgressbar
                  value={item.overallRating}
                  maxValue={10}
                  strokeWidth={4}
                  text={item.overallRating}
                  styles={{
                    path: {
                      width: "2px",
                      stroke: `#6A4993`,
                      strokeLinecap: "butt",
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      transformOrigin: "center center",
                    },
                    trail: {
                      stroke: "#2A1544",
                    },
                    text: {
                      fill: "white",
                      fontSize: "26px",
                    },
                  }}
                />
              </div>
            </div>

            <div className={styles.Questinfo}>
              <div className={styles.QuestInfoBlock}>
                <div className={styles.QuestinfoDescription}>{item.review}</div>
              </div>
            </div>
          </div>

          <div className={styles.pie}>
            <CircularProgressbar
              value={item.overallRating}
              maxValue={10}
              strokeWidth={4}
              text={item.overallRating}
              styles={{
                path: {
                  width: "2px",
                  stroke: `#6A4993`,
                  strokeLinecap: "butt",
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  transformOrigin: "center center",
                },
                trail: {
                  stroke: "#2A1544",
                },
                text: {
                  fill: "white",
                  fontSize: "26px",
                },
              }}
            />
          </div>
        </div>
      ))}
      {questCounts < reviewListJSON?.length && (
        <div className={styles.ShowMoreWrapper}>
          <div
            className={styles.ShowMoreButton}
            onClick={() => setQuestCounts((prevState) => prevState + 3)}
          >
            <Image
              src="/icons/showMore.svg"
              alt="address icon"
              width={25}
              height={24}
            />
            <span>Показать еще</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(ReviewsShowComponent);
