import React, { memo, PropsWithChildren, } from 'react'
import styles from './Card.module.scss';
import { makeStyles, } from '@material-ui/core/styles'
// import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import { useCoverCardMediaStyles, } from '@mui-treasury/styles/cardMedia/cover'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: '1rem',
    boxShadow: 'none',
    position: 'relative',
    minWidth: 250,
    minHeight: 360,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '64%',
      bottom: 0,
      zIndex: 1,
      background: 'linear-gradient(to top, #000, rgba(0,0,0,0))',
    },
  },
  content: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    width: '100%',
  },
}))

interface CardProps extends PropsWithChildren<any> {
  image: string,
  className: string,
}

const Card = (props: CardProps) => {
  const {
    image,
    className,
    children,
  } = props
  const mediaStyles = useCoverCardMediaStyles({ bgPosition: 'top', },)
  const styles = useStyles()
  return (
    <div {...{ className, }}>
      {/* <Card className={styles.card}>
        <CardMedia
          classes={mediaStyles}
          className="opacity-90"
          image={
            image
          }
        />
        <Box py={3} px={2} className={styles.content}>
          {children}
        </Box>
      </Card> */}
    </div>
  );
};
export default memo(Card);