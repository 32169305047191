import React from "react";
import PaginationButton from 'components/Molecules/PaginationButton'
import PaginationSplitter from 'components/Molecules/PaginationSplitter'

const PaginationSplit = ({ totalPages, currentPage, paginate }) => {
  const getPagesFromCount = (pagesCount) =>
    Array.from(Array(pagesCount).keys()).map((value) => value + 1);

  return currentPage <= 4 ? (
    <>
      {getPagesFromCount(currentPage + 2).map((pageNumber) => (
        <PaginationButton
          key={pageNumber}
          paginate={paginate(pageNumber)}
          isSelected={pageNumber === currentPage}
        >
          {pageNumber}
        </PaginationButton>
      ))}
      <PaginationSplitter />
      <PaginationButton paginate={paginate(totalPages)}>
        {totalPages}
      </PaginationButton>
    </>
  ) : currentPage > 4 && currentPage < totalPages - 3 ? (
    <>
      <PaginationButton paginate={paginate(1)}>1</PaginationButton>
      <PaginationSplitter />
      {[currentPage - 1, currentPage, currentPage + 1].map((pageNumber) => (
        <PaginationButton
          key={pageNumber}
          paginate={paginate(pageNumber)}
          isSelected={pageNumber === currentPage}
        >
          {pageNumber}
        </PaginationButton>
      ))}
      <PaginationSplitter />
      <PaginationButton paginate={paginate(totalPages)}>
        {totalPages}
      </PaginationButton>
    </>
  ) : (
    <>
      <PaginationButton paginate={paginate(1)}>1</PaginationButton>
      <PaginationSplitter />
      {getPagesFromCount(totalPages)
        .slice(currentPage - 3)
        .map((pageNumber) => (
          <PaginationButton
            key={pageNumber}
            paginate={paginate(pageNumber)}
            isSelected={pageNumber === currentPage}
          >
            {pageNumber}
          </PaginationButton>
        ))}
    </>
  );
};

export default PaginationSplit;
